@import url(https://fonts.googleapis.com/css2?family=Open+Sans);
body {
  margin: 0;
   /* font-family: -apple-system, BlinkMacSystemFont, 'Open Sans','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-field input[type=text] {
    text-align: center
}
.editor-link{
    display: inline;
    position: relative;
}

.editor-link-popup{
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 6px 10px;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid lightgray;
    width: -moz-fit-content;
    width: fit-content;
}

.editor-img{
    height: 15px;
}
.element-image{
    width: 100%;
}
.element-image img{
    width: 100%;
    height:100%;
}
.PageRichText_richTable__2_k3f{
    width:100%;
}

.PageRichText_richtd__3peWe{
    height: 50px;
    padding:0 5px;
}
.EditorToolbar_editToolbar__2GWEe .EditorToolbar_toolbar__kxkoo{
    border-radius: 10px;
background: #ffffff;
box-shadow:  -8px 8px 13px #ededed,
             8px -8px 13px #ffffff;

    display:flex;
    flex-wrap:wrap;
    align-items:center;
    padding: 15px 10px;
    grid-row-gap: 15px;
    row-gap: 15px;
}

.EditorToolbar_toolbargrp__v8CWz {
    margin-right: 10px;
    cursor: pointer;
}

.EditorToolbar_toolbargrp__v8CWz {
    margin:0 10px;
}

.EditorToolbar_toolbarSelect__Dq2Rg{
    height: 30px;
    border: none;
    width: 6.9rem;
}

.EditorToolbar_editToolbar__2GWEe button{
    background-color: white;
    border:none;
    opacity: 0.5;
}
.EditorToolbar_editToolbar__2GWEe .EditorToolbar_btnActive__38vQs{
    opacity: 1;
}

.EditorToolbar_editToolbar__2GWEe button{
    cursor: pointer;
}

.color-options{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}
.color-clicked{
  border: 1px solid lightgray;
  border-bottom: none;
}
.color-option,.hexPreview{
  width: 16px;
  height: 16px;
  background-color: #000000;

}
.color-picker form{
  display: flex;
  align-items: center;
  grid-column-gap: 1px;
  column-gap: 1px;
  width: 100%;
}
.color-picker input{
  width: 65%;
  height:1.3em;
  border:1px solid lightgray;
  border-radius: 5px;
  padding-left:5px
}
.color-picker button{
  margin:0;
  padding:0;
  cursor: pointer;
}
.color-picker input:focus{
  outline: none;
}

.color-picker .popup-wrapper{
  display: inline;
  position: relative;
}

.color-picker .popup{
  position: absolute;
  left: 0;
  background-color: white;
  padding: 6px 10px;
  border: 1px solid lightgray;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1;

}
.Embed_embedEdit__3K46c button{
    background-color: white;
    border:none;
    opacity: 0.5;
}
.Embed_embedEdit__3K46c .Embed_btnActive__-nOJd{
    opacity: 1;
}

.Embed_embedEdit__3K46c button{
    cursor: pointer;
}

.Embed_embedEdit__3K46c .Embed_popup-wrapper__qoQYa{
    display: inline;
    position: relative;
}

.Embed_embedEdit__3K46c .Embed_popup__1Z6on{
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1;

}
.Table_tableoption__NDAiW {
    display: flex;
    margin: 5px 2px;
    grid-gap: 5px;
    gap: 5px;
}

.Table_tableoption__NDAiW {
    white-space: nowrap;
}

.Table_tableinput__3UE4D {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-gap: 3px;
    gap: 3px;
}

.Table_tableunit__2AdPk {
    width: 15px;
    height: 15px;
    border: 1px solid lightgray;
}

.Table_editTable__22LJ6 .Table_popup-wrapper__3x86I{
    display: inline;
    position: relative;
}

.Table_editTablepopup__12jdL{
  position: absolute;
  left: 0;
  background-color: white;
  padding: 6px 10px;
  border: 1px solid lightgray;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1;

}

.Table_editTable__22LJ6 button{
    background-color: white;
    border:none;
    opacity: 0.5;
}

.Table_editTable__22LJ6 .Table_btnActive__gz7wZ{
    opacity: 1;
}
/*
blockquote{
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}
table, th, td {
    border: 1px solid black;
}
table{
    border-collapse: collapse;
}
button{
    background-color: white;
    border:none;
    opacity: 0.5;
}
.btnActive{
    opacity: 1;
}
.editor-wrapper{
    font-family:'Helvetica','Arial', sans-serif;
    border-radius: 10px;
background: #ffffff;
box-shadow:  -8px 8px 13px #ededed,
             8px -8px 13px #ffffff;
    min-height: 400px;
    height: fit-content;
}
table{
    width:100%;
}
td{
    height: 50px;
    padding:0 5px;
}
.popup-wrapper{
    display: inline;
    position: relative;
}
.popup{
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1;

}
button{
    cursor: pointer;
}
*/
.Editor_editor__2SWgi blockquote{
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}

.Editor_editor__2SWgi table, th, td {
    border: 1px solid black;
}

.Editor_editor__2SWgi table{
    border-collapse: collapse;
}

.Editor_editor__2SWgi button{
    background-color: white;
    border:none;
    opacity: 0.5;
}

.Editor_editor__2SWgi .Editor_btnActive__3k7oj{
    opacity: 1;
}

.Editor_editor__2SWgi .Editor_editor-wrapper__2Lmux{
    font-family:'Helvetica','Arial', sans-serif;
    border-radius: 10px;
background: #ffffff;
box-shadow:  -8px 8px 13px #ededed,
             8px -8px 13px #ffffff;
    min-height: 400px;
    height: -moz-fit-content;
    height: fit-content;
}

.Editor_editorTable__3maKf{
    width:100%;
}

.Editor_editortd__3wDVi{
    height: 50px;
    padding:0 5px;
}

.Editor_editor__2SWgi .Editor_popup-wrapper__1rbAq{
    display: inline;
    position: relative;
}

.Editor_editor__2SWgi .Editor_popup__369Ku{
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1;

}

.Editor_editor__2SWgi button{
    cursor: pointer;
}
