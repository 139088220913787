.embedEdit button{
    background-color: white;
    border:none;
    opacity: 0.5;
}
.embedEdit .btnActive{
    opacity: 1;
}

.embedEdit button{
    cursor: pointer;
}

.embedEdit .popup-wrapper{
    display: inline;
    position: relative;
}

.embedEdit .popup{
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1;

}