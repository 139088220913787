.tableoption {
    display: flex;
    margin: 5px 2px;
    gap: 5px;
}

.tableoption {
    white-space: nowrap;
}

.tableinput {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 3px;
}

.tableunit {
    width: 15px;
    height: 15px;
    border: 1px solid lightgray;
}

.editTable .popup-wrapper{
    display: inline;
    position: relative;
}

.editTablepopup{
  position: absolute;
  left: 0;
  background-color: white;
  padding: 6px 10px;
  border: 1px solid lightgray;
  height: fit-content;
  z-index: 1;

}

.editTable button{
    background-color: white;
    border:none;
    opacity: 0.5;
}

.editTable .btnActive{
    opacity: 1;
}