.editToolbar .toolbar{
    border-radius: 10px;
background: #ffffff;
box-shadow:  -8px 8px 13px #ededed,
             8px -8px 13px #ffffff;

    display:flex;
    flex-wrap:wrap;
    align-items:center;
    padding: 15px 10px;
    row-gap: 15px;
}

.toolbargrp {
    margin-right: 10px;
    cursor: pointer;
}

.toolbargrp {
    margin:0 10px;
}

.toolbarSelect{
    height: 30px;
    border: none;
    width: 6.9rem;
}

.editToolbar button{
    background-color: white;
    border:none;
    opacity: 0.5;
}
.editToolbar .btnActive{
    opacity: 1;
}

.editToolbar button{
    cursor: pointer;
}